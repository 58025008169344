* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  
  /* Firefox */
  scrollbar-width: thin;  
  scrollbar-color: #33BBBF #060F13;
}

/* Safari and Chrome */
::-webkit-scrollbar { 
  width: 13px;
  background-color: #060F13;
}

::-webkit-scrollbar-thumb{
  background: #33BBBF;
}